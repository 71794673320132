<template>
  <v-card class="elevation-3 ma-5">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>ご意見フォーム</v-toolbar-title>
    </v-toolbar>
    <v-list-item>
      <v-card-title>項目を選択</v-card-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-form v-model="valid">
      <v-list-item>
        <v-select
          v-model="selected"
          :items="choice"
          placeholder="内容を選択してください"
          single-line
          :rules="[required]"
        ></v-select>
      </v-list-item>
      <v-list-item>
        <v-textarea
          v-model="message"
          label="ご意見"
          placeholder="例: アプリが反応しない"
          :rules="[required]"
        ></v-textarea>
      </v-list-item>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="!valid"
          :loading="loading"
          :dark="valid"
          @click="sendMessage"
          >ご意見を送信
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";
import { EventBus } from "@/event.js";

export default {
  data() {
    return {
      valid: false,
      required: (value) => !!value || "必ず入力が必要です",
      loading: false,
      selected: null,
      message: "",
      choice: ["使い方がわからない", "正しく動作しない", "改善してほしい"],
    };
  },
  metaInfo: {
    title: "コンタクト",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "サービスに対するご意見をご投稿ください。",
      },
    ],
  },
  methods: {
    async sendMessage() {
      // API call
      this.loading = true;
      const method = "post";
      const url = `${process.env.VUE_APP_SEARCH_API_ENDPOINT}/contact`;
      const type = this.selected;
      const message = this.message;
      const userAgent = navigator.userAgent;
      const data = {
        type,
        message,
        userAgent,
      };

      axios({ method, url, data }).then(
        (response, err) => {
          if (err) {
            EventBus.$emit("error", "エラーが発生しました");
            this.loading = false;
            this.$router.push({ name: "Around" });
          } else {
            this.loading = false;
            EventBus.$emit("success", "ご意見ありがとうございました");
            this.$router.push({ name: "Around" });
          }
        }
      );
    },
  },
};
</script>